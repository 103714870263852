
export default {
  name: 'PagesRegisterSteps',
  data() {
    return {
      isVisible: false,
      activeStep: 1,
      communitys: [],
      startPackUsers: [],
      checkedCommunitys: [],
      checkedAccounts: [],
      scrollTop: 0,
    }
  },
  async fetch() {
    if (this.$auth.loggedIn) {
      await Promise.all([this.fetchCommunites(), this.fetchStartPackUsers()])
    }
  },
  computed: {
    isAllowGoToStep2() {
      return this.checkedCommunitys?.length > 0
    },
    stepNextButtonText() {
      switch (this.activeStep) {
        case 1:
          return this.checkedCommunitys?.length > 0
            ? 'Продолжить'
            : 'Необходимо выбрать не менее одного сообщества'
        case 3:
          return 'Перейти в ленту'

        default:
          return this.checkedCommunitys?.length > 0
            ? 'Продолжить'
            : 'Необходимо выбрать не менее одного сообщества'
      }
    },
    stepsTitle() {
      switch (this.activeStep) {
        case 1:
          return 'Что бы вы хотели видеть чаще?'
        case 2:
          return 'Стартовый комплект подписки'
        case 3:
          return 'Стартовый комплект подписки'

        default:
          return 'Что бы вы хотели видеть чаще?'
      }
    },
    stepsText() {
      switch (this.activeStep) {
        case 1:
          return 'Выберите темы,  для того чтобы видеть полезные материалы в медиа-центре и персональной ленте'
        case 2:
          return 'Мы подготовили для вас подборку профилей , чтобы вы могли видеть на старте материалы в медиа-центре и ленте'
        case 3:
          return 'Мы подготовили для вас подборку профилей , чтобы вы могли видеть на старте материалы в медиа-центре и ленте'

        default:
          return 'Выберите темы,  для того чтобы видеть полезные материалы в медиа-центре и персональной ленте'
      }
    },
  },
  watch: {
    '$auth.loggedIn'() {
      if (this.$auth.loggedIn) {
        this.open()
      }
    },
  },

  beforeDestroy() {
    document.body.classList.remove('not-overflow')
  },
  mounted() {
    this.open()
  },

  methods: {
    async fetchCommunites() {
      this.communitys = await this.$axios.$get('/collection', {
        params: {
          statistics: false,
        },
      })
    },
    async fetchStartPackUsers() {
      const users = await this.$axios.$get('/users/startPack/active')

      if (users?.length) {
        this.checkedAccounts = users.map((user) => user._id)
        this.startPackUsers = users
      }
    },
    sortAccounts() {
      if (this.startPackUsers?.length) {
        const sortedBySubscriptions = this.startPackUsers.sort(
          (user1, user2) =>
            Number(
              this.isInSubscription(
                user2.statistics?.forStreams.map((sub) => sub._id)
              )
            ) -
            Number(
              this.isInSubscription(
                user1.statistics?.forStreams.map((sub) => sub._id)
              )
            )
        )
        this.checkedAccounts = sortedBySubscriptions.map((user) => user._id)
        this.startPackUsers = sortedBySubscriptions
      }
    },
    isInSubscription(streams) {
      const res = streams.filter((streamId) => {
        const streamsIds = this.$auth.user.subscriptions?.streams?.map(
          (el) => el.stream
        )
        return streamsIds.find((id) => id === streamId)
      })
      return !!res?.length
    },
    update() {
      this.$emit('update')
    },
    async subscribeToCommunitys() {
      if (this.checkedCommunitys?.length) {
        for (const community of this.checkedCommunitys) {
          await this.$axios.$post('/users/subscribe', {
            entity: 'COLLECTION',
            entityId: community,
          })
        }

        await this.$auth.fetchUser()
        this.$eventBus.$emit('updateSubscriptions')
      }
    },
    async subscribeToAccounts() {
      if (this.checkedAccounts?.length) {
        const promises = this.checkedAccounts.map(async (user) => {
          await this.$axios.$post('/users/subscribe', {
            entity: 'AUTHOR',
            entityId: user,
          })
        })

        await Promise.all(promises)
        await this.$auth.fetchUser()
        this.$eventBus.$emit('updateSubscriptions')
      }
    },
    async goToNextStep() {
      switch (this.activeStep) {
        case 1:
          await this.subscribeToCommunitys()
          this.initStep2()
          break
        case 3:
          await this.subscribeToAccounts()
          this.initStep3()
          break
      }
    },
    initStep2() {
      this.activeStep = 2
      this.sortAccounts()
      setTimeout(() => {
        this.activeStep = 3
      }, 5000)
    },
    initStep3() {
      this.activeStep = 3
      this.close()
      this.$router.push('/')
    },
    open() {
      if (
        this.$auth.loggedIn &&
        this.$auth.user?.type === 'USER' &&
        this.$cookies.get('newUser') &&
        !this.$cookies.get('newUser_later')
      ) {
        const { body, documentElement } = document
        this.scrollTop = documentElement.scrollTop
        body.style.top = `-${this.scrollTop}px`
        document.body.classList.add('not-overflow')
        this.isVisible = true
      }
    },
    remindLater() {
      this.$cookies.set('newUser_later', true, {
        path: '/',
        maxAge: 60 * 60 * 24, // 24 часа
      })

      const { body, documentElement } = document
      document.body.classList.remove('not-overflow')
      documentElement.scrollTop = this.scrollTop
      body.style.removeProperty('top')
      this.isVisible = false
      this.activeStep = 1
    },

    close() {
      const { body, documentElement } = document
      document.body.classList.remove('not-overflow')
      documentElement.scrollTop = this.scrollTop
      body.style.removeProperty('top')
      this.isVisible = false
      this.activeStep = 1
      this.$cookies.remove('newUser')
      this.$cookies.remove('newUser_later')
    },
  },
}
